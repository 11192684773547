<template>
  <div>
    <div v-if="loader">
      Loading...
    </div>
    <div v-if="!loader"></div>
    <div v-if="errs =='NOT_FREE_BOX'">
      <!-- <h1>אין תיבות פנויות</h1> -->
      
      <div class="receving_packages_banner">
      <div class="comman_banner">
        <h2></h2>
      </div>
      <h1> ...בדיוק נגמר לנו המקום </h1>
      <h1> אנא פנו ישירות ללקוח אליו מיועדת החבילה </h1>
      </div>
    </div>
    <div v-if ="errs==''">
    <div class="receving_packages_banner">
      <div class="comman_banner">
        <h2>הכנסת חבילה חדשה</h2>
      </div>
      <div class="container">
        <div class="singup_page">
          <form @submit="to_box" class="singup_form">
            <p class="error_text" style="display: none">אופס, שכחת משהו</p>
            <p class="error_text">{{ errs }}</p>
            <div class="form-group">
              <label>מספר חבילה</label>
              <input
                type="text"
                class="form-control"
                v-model="package_number"
              />
            </div>
            <div class="form-group">
              <label>מספר הטלפון הנייד של מקבל החבילה</label>
              <select name="" id="" class="form-control ltr" v-model="user_id">
                <option
                  class="ltr"
                  v-for="phone in users"
                  :value="phone.user_id"
                  :key="phone.user_id"
                  >{{ phone.phone_number }} - {{ phone.name }}</option
                >
              </select>
              <!-- <label>מספר הטלפון של מקבל החבילה</label>
              <v-select  id="mySelect" :options="users" :value="users.phone_number" label="multi" v-model="phone_number"
              
   
              ></v-select> -->
            </div>
            <!-- <div class="form-group">
              <label>בחירת גודל תיבה</label>
              <select name=""  class="form-control" v-model="size">
                <option value="j">ג'מבו (90x90)</option>
                <option value="l">גדול (11.5x40)</option>
                <option value="m">בינוני (24.5x40)</option>
                <option value="s">קטן (45x40)</option>
              </select>
            </div> -->
            <input type="submit" class="comman_anchor" value="לחצו לפתיחת התיבה" />
          </form>
        </div>
        <!-- <span class="warning_message"
          >שימו לב! עם לחיצה על כפתור “הוספה“ תישלח הודעה מיידית ללקוח</span
        > -->
        <h3 
          >לאחר הכנסת החבילה, אנא הקפידו לסגור את דלת התיבה</h3
        >
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import BackendModel from "../../models/BackendModel";
// import PointGlobalHeader from "./PointGlobalHeader";
import("./casuals_couriers/css/style.css");
export default {
  name: "CasualCouriersAddPackage",
  components: {
    // PointGlobalHeader,
  },
  data() {
    return {
      loader: true,
      data: [],
      users: [],
      package_number: "",
      errs: "",
      global_err: "",
      unit_id: "",
      phone_number: "",
      user_id: "",
    };
  },
  async mounted() {
    const backendModel = new BackendModel();
    this.loader = true;
    this.unit_id = this.$route.query.unit_id;
    let casual_courier_session_id = backendModel.getCasualCourierId();
    let authRes = await backendModel.CasualCourierRequest(
      "/Api/service/backoffice/casual_courier_auth",
      { casual_courier_session_id }
    );
    if (typeof authRes == "undefined") {
      this.global_err = "Backend is down?";
    } else if (authRes.data.res == "ERROR") {
      this.global_err = authRes.data.e;
    } else if (authRes.data.res == "OK") {
      let data = await backendModel.CasualCourierRequest(
        "/Api/service/backoffice/casual_courier_get_unit_data",
        {
          casual_courier_session_id: casual_courier_session_id,
          unit_id: this.unit_id,
        }
      );
      console.log(data.data)
      if(data.data.err =="NOT_FREE_BOX"){
        this.errs= "NOT_FREE_BOX"
        this.loader=false
      }
      this.data = data.data.data;
      console.log(this.data);
      this.users = this.data.users;
      console.log(this.users);
      console.log(this.unit_id);
      this.loader = false;
      console.log(this.loader)
    } else if (
      authRes.data.res.courier_company_session_id == "" ||
      authRes.data.res == "NOK"
    ) {
      await this.$router.push({
        path: "/casual_couriers/casual_couriers_login",
        query: { unit_id: this.unit_id },
      });
    }
    this.loader = false;
    this.errs = "";
  },
  methods: {
    async to_box(e) {
      e.preventDefault();
      console.log(e, this.unit_id);

      const backendModel = new BackendModel();
      this.loader = true;
      let casual_courier_session_id = backendModel.getCasualCourierId();
      let res = await backendModel.CasualCourierRequest(
        "/Api/service/backoffice/casual_courier_find_box",
        {
          unit_id: this.unit_id,
          user_id: this.user_id,
          package_number: this.package_number,
        }
      );
      if (res.data.res == "NOK" && res.data.err == "less_data") {
        this.errs = "אופס, שכחת משהו";
      }
      if (res.data.res == "NOK") {
        this.errs = "שגיאה";
      }
      if (res.data.res == "OK") {
        let box_id = res.data.box_id;
        let box_number = res.data.box_number;
        await this.$router.push({
          path: "/casual_couriers/package_validation",
          query: { box_id: box_id, box_number: box_number },
        });
      }
    },

    //         let session_id = res.data.session_id;
    //         let CookieDate = new Date();
    //         CookieDate.setFullYear(CookieDate.getFullYear() + 10);
    //         document.cookie =
    //           "point_session_id =" +
    //           session_id +
    //           "; expires=" +
    //           CookieDate.toUTCString() +
    //           ";";
    //         await this.$router.push({ path: "/point/point_home" });
    //         this.loader_prop = false;
    //       }
    //     },
  },
};
</script>

<style>
@import "./casuals_couriers/css/style.css";
</style>
